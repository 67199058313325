import React from 'react';
import Notices from '../Notices';
import { Box } from '@mui/material';
import '../../../App.css'
const WinderRoadConditions = ()=>{
    
    const currentYear= new Date().getFullYear()
    const currentMonth=new Date().getMonth() +1
    var startDate=""
    var endDate=""
    // const nextYear= currentYear+1
    if(currentMonth >4){
    startDate= new Date("15-NOV-"+currentYear)
    endDate= new Date("15-APR-"+(currentYear+1))
    }else{
        startDate=new Date("15-NOV-"+(currentYear-1))
        endDate=new Date("15-APR-"+currentYear)
    }
    const currentDate=new Date();
     console.log("Dateeeeeeeeeeeeeeeeee:"+currentMonth,startDate, endDate, currentDate)
    if(currentDate >= startDate && currentDate <= endDate){
    return (
       
        
       <div> 
        <Box>
            <img src={process.env.PUBLIC_URL + "/logos/WinterRoadConditions.png"} alt='WinterRoadConitions logo' width={30} height={30}/> <b> Winter Road Conditions </b> 
            <ul > 
                <li>
                    <a href='http://www.gettingaroundillinois.com/winterconditions/' target='_blank' rel="noopener noreferrer" className="hyper-links"> Illinois </a>
                </li>
                <li>
                    <a href='https://www.in.gov/indot/travel-conditions/travel-information/' target='_blank' rel="noopener noreferrer" className="hyper-links"> Indiana </a>
                </li>
                <li>
                    <a href='https://511ia.org/@-99.06095,41.71659,7?show=winterDriving [511ia.org]' target='_blank' rel="noopener noreferrer" className="hyper-links"> Iowa </a>
                </li>
                <li>
                    <a href='https://mdotjboss.state.mi.us/MiDrive/map'  target='_blank' rel="noopener noreferrer" className="hyper-links"> Michigan </a>
                </li>
                <li>
                    <a href='https://www.modot.org/winter-road-conditions' target='_blank' rel="noopener noreferrer" className="hyper-links"> Missouri </a>
                </li>
                <li>
                    <a href='https://511wi.gov/list/winterroadconditions?start=0&length=25&order%5Bi%5D=1&order%5Bdir%5D=asc [511wi.gov]' target='_blank' rel="noopener noreferrer" className="hyper-links"> Wisconsin </a>
                </li>
                <li>
                    <a href='https://goky.ky.gov/' target='_blank' rel="noopener noreferrer" className="hyper-links"> Kentucky </a>
                </li>
            </ul>

        </Box>
       </div>
    )
    }else{
        return null
    }
}

const MyNotices = (props) => {
    return (
        <div>
            <WinderRoadConditions/>
             <Notices {...props}/>
       
       </div>
    )
}


const module = {
    routeProps: {
        path: 'Weather',
        component: MyNotices
    },
    name: 'weather',
    label: "Weather Advisories",
    title: "Travel Midwest Weather Advisories",
    showOnMenu: true,
}

export default module;